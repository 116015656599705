<template>
  <div class="page">
    <div class="row">
      <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
        <b-card>
          <div class="flex-justify-between">
            <b-button
              variant="outline-primary"
              class="mb-2"
              @click="$router.push({name: 'Create Brokerage'})"
            >
              <feather-icon icon="PlusIcon" />
              Add New User
            </b-button>
            <b-button
              variant="outline-primary"
              class="mb-2"
              @click="syncAgents"
            >
              <feather-icon icon="DownloadCloudIcon" />
              Sync Members
            </b-button>
          </div>
          <b-table
            responsive="sm"
            :items="users"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <template #cell(name)="data">
              <router-link :to="'/brokerage/' + data.item.id">
                {{ data.value }}
              </router-link>
            </template>
            <template #cell(action)="data">
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item @click="$router.push({ name: 'Edit Brokerage', params: {id: data.item.id} })">
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item @click="removeAgent(data.item)">
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Remove</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>

          <b-pagination
            v-model="currentPage"
            class="d-flex justify-content-center"
            :total-rows="rows"
            :per-page="perPage"
            aria-controls="my-table"
          />
        </b-card>
      </div>
    </div>
    <b-modal
      ref="my-modal"
      v-model="isModal"
      title="Sync Brokerage Members"
      ok-title="Submit"
      cancel-variant="outline-secondary"
      centered
      @ok="handleOk"
      @hidden="resetModal"
    >
      <form
        ref="form"
        @submit.stop.prevent="handleOk"
      >
        <b-form-group
          label="Password"
          :state="passwordState"
          label-for="password-input"
          invalid-feedback="Password is required"
        >
          <b-form-input
            id="password-input"
            v-model="password"
            :state="passwordState"
            required
            type="password"
          />
        </b-form-group>
        <b-alert
          v-if="errorMsg"
          show
          variant="danger"
        >
          <div class="alert-body">
            {{ errorMsg }}
          </div>
        </b-alert>
      </form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, BFormGroup, BFormInput, BDropdown, BDropdownItem, BAlert, BPagination,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard, BTable, BButton, BFormGroup, BFormInput, BDropdown, BDropdownItem, BAlert, BPagination,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      isModal: false,
      isSyncAgents: false,
      isRemoveAgent: false,
      fields: ['name', 'mlsId', 'email', 'phone', 'created', 'action'],
      password: '',
      passwordState: null,
      brokerage: {
        name: '',
        email: '',
        phone: '',
        profile_image: '',
      },
      errorMsg: '',
      tempAgent: {},
      users: [],
    }
  },
  computed: {
    rows() {
      return this.users.length
    },
  },
  created() {
    this.$store.dispatch('offices/getMembers').then(response => {
      this.showAgents(response.data)
    }).catch(err => {
      console.error(err)
    })
  },
  methods: {
    showAgents(response) {
      if (response) {
        this.users = response.map(data => {
          return {
            id: data.id,
            name: data.name,
            mlsId: data.agent_mls_loginName,
            email: data.email,
            phone: data.phone,
            created: this.formatDate(data.created_at),
          }
        })
      }
    },
    syncAgents() {
      this.isModal = true
      this.isSyncAgents = true
    },
    removeAgent(data) {
      this.isModal = true
      this.isRemoveAgent = true
      this.tempAgent = data
    },
    removeMember() {
      const data = {
        id: this.tempAgent.id,
        password: this.password,
      }
      this.$store.dispatch('offices/removeAgent', data).then(response => {
        if (response.code === 200) {
          this.users = this.users.filter(obj => {
            return obj.id !== data.id
          })
          this.showToast('Success', 'CheckCircleIcon', 'Agent has been removed successfully', 'success', 'bottom-right')
          this.resetModal()
        } else {
          this.errorMsg = response.message
          this.passwordState = null
        }
      }).catch(err => {
        console.error(err)
      })
    },
    formatDate(date) {
      return new Date(date).toLocaleString()
    },
    resetModal() {
      this.password = ''
      this.passwordState = null
      this.isSyncAgents = false
      this.isRemoveAgent = false
      this.tempAgent = {}
      this.isModal = false
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.passwordState = valid
      return valid
    },
    syncMembers() {
      this.$store.dispatch('offices/syncMembers', { password: this.password }).then(response => {
        if (response.code === 200) {
          this.showAgents(response.data.members)
          this.showToast('Success', 'CheckCircleIcon', 'Brokerage agents have been synced successfully', 'success', 'bottom-right')
          this.resetModal()
        } else {
          this.errorMsg = response.message
          this.passwordState = null
        }
      }).catch(err => {
        console.error(err)
      })
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      if (!this.checkFormValidity()) {
        return
      }
      if (this.isSyncAgents) {
        this.syncMembers()
      } else if (this.isRemoveAgent) {
        this.removeMember()
      }
    },
    showToast(title, icon, text, variant, position) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text,
          variant,
        },
      },
      {
        position,
      })
    },
  },
}
</script>

<style>

</style>
